import { AccountSignupData } from "../types/account";
import { fetchApi, handleHttpResponse, requestGet, requestPost } from "./api";

export const signupAccount = async (data: AccountSignupData) => {
    return await fetchApi('/v1/account/signup', requestPost(data)).then(handleHttpResponse);
}

export const checkAccountStatus = async (login: string) => {
    return await fetchApi(`/v1/account/check_status/${encodeURIComponent(login)}`, requestGet()).then(handleHttpResponse);
}

export const confirmAccount = async (login: string, key: string) => {
    return await fetchApi(`/v1/account/confirm`, requestPost({ login: login, key: key })).then(handleHttpResponse);
}

export const sendAccountConfirmation = async (login: string, url: string) => {
    return await fetchApi(`/v1/account/send_confirmation`, requestPost({ login: login, url: url })).then(handleHttpResponse);
}

export const changeAccountPassword = async (login: string, password: string, new_password: string, confirm_password: string) => {
    return await fetchApi(`/v1/account/change_password`, requestPost({ login: login, password: password, new_password: new_password, confirm_password: confirm_password })).then(handleHttpResponse);
}