import classNames from 'classnames';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { uuid } from '../../services/utils';
import styles from './Notices.module.scss';

export type Notice = {
    type: string;
    message?: string;
    messageNode?: ReactNode;
    state: string;
    id: string;
};

type NoticesProps = {
    //notices: Notice[];
};

export const raiseNoticeNode = (type: string, message: ReactNode) => {
    var e = document.createEvent('HTMLEvents');
    e.initEvent('rdc_notice', true, true);
    (e as any).noticeType = type;
    (e as any).noticeMessageNode = message;

    window.dispatchEvent(e);
}

export const raiseNotice = (type: string, message: string) => {
    var e = document.createEvent('HTMLEvents');
    e.initEvent('rdc_notice', true, true);
    (e as any).noticeType = type;
    (e as any).noticeMessage = message;

    window.dispatchEvent(e);
    
}



export const Notices: FC<NoticesProps> = ({ }) => {
    const [notices, setNotices] = useState<Notice[]>([]);

    const fadeInNotice = (notice_id: string) => {
        setTimeout(() => setNotices((prev) => (prev.map(n => n.id == notice_id ? {...n, state: 'shown'} : n))), 500);
        setTimeout(() => setNotices((prev) => (prev.map(n => n.id == notice_id ? {...n, state: 'shownfinal'} : n))), 1500);
    }

    const hideNotice = (notice_id: string) => {
        setNotices((prev) => (prev.map(n => n.id == notice_id ? {...n, state: 'hidden'} : n)));
        setTimeout(() => setNotices((prev) => (prev.filter(n => n.id != notice_id))), 1000);
    }

    useEffect(() => {
        window.addEventListener('rdc_notice', (e) => {
            const notice_id = uuid();
            if ((e as any).noticeMessage)
                setNotices((prev) => ([...prev, { type: (e as any).noticeType, message: (e as any).noticeMessage, state: 'init', id: notice_id }]));
            else if ((e as any).noticeMessageNode)
                setNotices((prev) => ([...prev, { type: (e as any).noticeType, messageNode: (e as any).noticeMessageNode, state: 'init', id: notice_id }]));
            fadeInNotice(notice_id);
            setTimeout(() => hideNotice(notice_id), 10000);
        });
      }, []);

    const delNotice = (index: number) => {
        setNotices((prev) => ([...prev.filter((x, ind) => { return ind != index; })]));
    };

    return (
        <div className={styles.notices_list}>
            {notices.map((item:Notice, index) => (
                <div key={'notice-' + index} className={classNames(styles.notice, styles[item.type], styles[item.state])} >
                    {item.message && (<div dangerouslySetInnerHTML={{__html: item.message}}></div>)}
                    {item.messageNode && (item.messageNode)}
                    <a className={styles.btn_close} onClick={() => delNotice(index)}></a>
                </div>
            ))}
        </div>
    )
};
