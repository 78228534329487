export function uuid() {
    return Number(String(Math.random()) + Date.now())
      .toString(32)
      .slice(2);
  }

  export function setCookie(
    name: string,
    value: string | null,
    props?: {
      [key: string]: Date | string | number | boolean;
    },
  ) {
    props = props || {};
    if (typeof props.path === 'undefined') { props.path = '/'; }
    let exp = props.expires;
    if (exp !== undefined && typeof exp === 'number') {
      const d = new Date();
      d.setTime(d.getTime() + exp * 1000);
      exp = props.expires = d;
    }
    if (exp !== undefined && typeof exp === 'object') {
      props.expires = exp.toUTCString();
    }
    if (typeof value === 'string') {
      value = encodeURIComponent(value);
    }
    let updatedCookie = `${name}=${value}`;
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const propName in props) {
      updatedCookie += `; ${propName}`;
      const propValue = props[propName];
      if (propValue !== true) {
        updatedCookie += `=${propValue}`;
      }
    }
    document.cookie = updatedCookie;
  }
  
  export function deleteCookie(name: string) {
    setCookie(name, null, { expires: -1 });
  }

  export const getCookie = (name: string):string => {
    const matches = document.cookie.match(
      new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`),
    );
    return matches ? decodeURIComponent(matches[1]) : '';
  }

  export const validateRequired = (value: any):string|undefined => {
    return ((value ?? '') == '') ? 'Обязательное поле' : undefined;
  }

  export const validateReqEmail = (value: any):string|undefined => {
    return /^[^@]+@[^@]+\.[^@]+$/.test(value) ? undefined : 'Неверный формат e-mail';
  }

  export const validateTime = (value: any):string|undefined => {
    if (!value)
      return undefined;
    return /^[012]?\d{1,1}:[012345]\d{1,1}$/.test(value) ? undefined : 'Введите время в формате ЧЧ:ММ';
  }

  export const validateReqFIO = (value: any):string|undefined => {
    return /^.*[^\s]+\s+[^\s+].*$/.test(value) ? undefined : 'Введите минимум 2 слова';
  }

  export const emitEvent = (event_type: string, data:any) => {
    var e = document.createEvent('HTMLEvents');
    e.initEvent(event_type, true, true);
    for (var k in data)
      (e as any)[k] = data[k];

    window.dispatchEvent(e);
      
  }

  export const passwordIsValid = (password: string) => {
    if (password && password.length > 7) {
        if (/.*[0-9].*/.test(password) && /.*[^0-9a-zA-Z\s].*/.test(password) && !/.*\s.*/.test(password) && !/.*[а-яА-Я].*/.test(password)
            && /.*[a-z].*/.test(password) && /.*[A-Z].*/.test(password))
            return true;
    }
    return false;
}

export const validatePassword = (value: any):string|undefined => {
    if ((value ?? '') == '') {
        return 'Обязательное поле';
    } else if (!passwordIsValid(value ?? ''))
        return 'Ненадежный пароль';
    return undefined;
}