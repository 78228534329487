import React, { ChangeEvent, ChangeEventHandler, FC, ReactElement, ReactNode, useEffect, useState } from 'react';
import styles from './DataGrid.module.scss';
import classNames from 'classnames';
import { Checkbox } from '../Checkbox';
import parse from 'html-react-parser';
import { uuid } from '../../services/utils';

export type DataGridColumn = {
    title?: string;
    property?: string;
    type: string;
    renderHeader?: () => string;
    renderValue?: (row: any) => string;
    renderNode?: (row: any) => ReactElement;
    valueNode?: ReactElement;
};

type DataGridProps = {
    columns: DataGridColumn[];
    data: any[];
    selectedIds?: string[];
    onSelected?: (row: any, selected: boolean) => void;
};

export const DataGrid: FC<DataGridProps> = ({ columns, data, selectedIds = [], onSelected }) => {

    const cid = uuid();

    const selectorChange = (row:any, checked: boolean) => {
        if (checked) {
            if (onSelected) onSelected(row, true);
        } else {
            if (onSelected) onSelected(row, false);
        }
        
    };

    return (
        <div className={styles.table_wrap}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        {columns.map((col, index) => <th key={cid + '-th-' + index} className={styles['th-' + col.type]}><div className={styles.inner}>{ col.type == 'selector' ? (<Checkbox label='' style='black' />) : (col.renderHeader ? parse(col.renderHeader()) : col.title ) }</div></th>)}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, tr_ind) => <tr key={cid + '-tr-' + tr_ind}>
                        {columns.map((col: DataGridColumn, col_ind:number) => <td key={cid + '-td-' + tr_ind + '-' + col_ind} className={classNames(styles['td-' + col.type], { [styles.checked]: selectedIds.some(x => x == row.id) })}><div className={styles.inner}>
                        
                            {col.type == 'selector' && (
                                <Checkbox label='' style='black' defaultChecked={selectedIds.includes(row.id)} onChange={(e) => selectorChange(row, (e.target as any).checked)} />
                            )}
                            {col.renderNode ? (<>{col.renderNode(row)}</>) : (<>{row[col.property ?? '']}</>)}
                        </div></td>)}
                    </tr>)}
                </tbody>
            </table>
        </div>
    );
};