import { fetchApi, handleHttpResponse, requestGet, requestPost } from "./api";

export const listDomains = async (offset: number, limit: number) => {
    return await fetchApi('/v1/domain/list', requestGet()).then(handleHttpResponse);
}

export const listDomainsForAdding = async (search: string, assessmentId: number) => {
    return await fetchApi(`/v1/domain/list_for_adding?search=${encodeURIComponent(search)}&assessmentId=${assessmentId}`, requestGet()).then(handleHttpResponse);
}

export const addDomainToAssessment = async (domainId: number, assessmentId: number) => {
    return await fetchApi(`/v1/domain/${domainId}/add_to_assessment/${assessmentId}`, requestPost()).then(handleHttpResponse);
}

export const addDomainNameToAssessment = async (domainName: string, assessmentId: number) => {
    return await fetchApi(`/v1/domain/add_to_assessment/${assessmentId}`, requestPost({ name: domainName })).then(handleHttpResponse);
}

export const deleteAssessmentDomains = async (ids: string[], users_domain_id?: string) => {
    return await fetchApi(`/v1/domain/delete_from_assessment?ids=${ids.join(',')}` + (users_domain_id ? ('&users_domain_id=' + users_domain_id) : ''), requestGet()).then(handleHttpResponse);
}