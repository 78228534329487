import React, { useEffect, useState } from 'react';
import styles from './Login.module.scss';
import { TextInput } from '../../components/TextInput';
import { Checkbox } from '../../components/Checkbox';
import { Button } from '../../components/Button';
import { IconButton } from '../../components/IconButton';
import { ReactComponent as SocialVk } from '../../assets/img/social-vk.svg';
import { ReactComponent as SocialY } from '../../assets/img/social-y.svg';
import { PasswordInput } from '../../components/PasswordInput';
import { authUser, getUserInfo, handleHttpError } from '../../services/api';
import { getCookie, setCookie, validateRequired } from '../../services/utils';
import { validateHeaderName } from 'http';
import { checkAccountStatus } from '../../services/account';
import { raiseNotice } from '../../components/Notices/Notices';

export function Login() {
    require('../../assets/css/login.css');

    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false);
    
    const doLogin = () => {
        setShowValidationErrors(true);

        if (validate()) {
            checkAccountStatus(login).then(is_deleted => {
                
                if (typeof(is_deleted) == 'boolean' && is_deleted)
                    raiseNotice('error', 'Пользователь заблокирован');
                if (typeof(is_deleted) == 'boolean' && !is_deleted) {
                    authUser(login, password).then(res => {
                        if (res) {
                            setCookie('auth', res.cookie_auth);
                            window.location.href = '/assessments/';
                        }
                    }).catch(handleHttpError);
                }
            });
        }
    };

    const validate = ():boolean => {
        var r = true;

        r = r && (login ?? '') != '';
        r = r && (password ?? '') != '';

        return r;
    }

    useEffect(() => {
        getUserInfo().then(user => {
            if (user)
                window.location.href = '/assessments/';
        });
    }, []);

    return (
        <div className='loginForm'>
            <div className='left'>
                <div className='inner'>
                    <div className='title'>БССГ</div>
                    <div className='subtitle'>Оценка состоятельности<br/>в области управления данными</div>
                </div>
            </div>
            <div className='right'>
                <div className='inner'>
                    <div className='title'>Вход</div>
                    <a href="/signup" className='lnkSignup'>Зарегистрироваться</a>
                    <div className='data'>
                        <TextInput label='Логин' showValidationError={showValidationErrors} validate={validateRequired} value={login} onChange={(e) => { setLogin(e.target.value); }} />
                        <PasswordInput label='Пароль' value={password} onChange={(e) => { setPassword(e.target.value); }} />
                        <Checkbox label='Запомнить пароль' />
                    </div>
                    <div className='bottom'>
                        <Button label='Войти' type='login' onClick={doLogin}></Button>
                        <div className='social'>
                            <label>Вход через</label>
                            <IconButton icon={<SocialVk/>}></IconButton>
                            <IconButton icon={<SocialY/>}></IconButton>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}