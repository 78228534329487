import base64 from "base-64";
import { raiseNotice, raiseNoticeNode } from "../components/Notices/Notices";
import { sendAccountConfirmation } from "./account";
import { getCookie } from "./utils";


const { REACT_APP_RDC_API_URL = '' } = process.env;

export const API_URL = REACT_APP_RDC_API_URL;

export function handleHttpError(err: Error) {
    if (err.name != 'AbortError') // cancelled ajax request
    { 
        console.log('Handle error', err);
        //(window as any).notices.addNotice('error', err.message); 
    }
}

export function handleHttpResponse(resp: Response, handleError: (type:string, msg:string) => void = raiseNotice) {
    switch (resp.status) {
      case 200:
          resp.clone().json().then((json) => {
            if (json.errors !== undefined && json.errors.length > 0) {
              json.errors.forEach((element: any) => {
                handleError('error', element.message);
              });
            }
          }).catch(handleHttpError);
        return resp.json();
      case 404:
        handleError('error', 'Объект не найден');
        break;
      case 401: case 403:
        handleError('error', 'Неверный логин или пароль');
        break;
      case 400: case 500:
        try {
          resp.clone().json().then((json) => {
            if (json.errors !== undefined && json.errors.length > 0) {
              json.errors.forEach((element: any) => {
                var msg = element.message;
                if (!msg)
                  msg = 'Ошибка';
                  handleError('error', msg);
              });
            } else if (json.error_message) {
              if (json.error_message == 'ERROR_CONFIRM_EMAIL') {
                raiseNoticeNode('error', <div>Для входа в личный кабинет, пожалуйста, подтвердите адрес электронной почты.<br/><a href="#" onClick={(e) => { e.stopPropagation(); e.preventDefault(); sendAccountConfirmation(json.params.login, window.location.href).then(r => { raiseNotice('info', 'Письмо отправлено'); }).catch(handleHttpError); }}>Отправить письмо повторно</a></div>);
              } else
                handleError('error', json.error_message);
            } else {
              handleError('error', `Ошибка ${resp.statusText}`);
            }
          })
          .catch(handleHttpError);
        } catch (e) {
          console.log('exc', e);
          throw Error("Exxxxception");
        }
        return resp.json();
      default:
        handleError('error', `Ошибка ${resp.statusText}`);
    }
  }

export const requestGet = ():RequestInit => {
    return {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: getCookie('auth') ? {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + getCookie('auth')
          } : {
            'Content-Type': 'application/json',
          },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        //signal: signal
      };
}

export const requestPost = (body: object | string | null = null):RequestInit => {
  const options: RequestInit = {
    method: 'POST',
    cache: 'no-cache',
    headers: getCookie('auth') ? {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Basic ${getCookie('auth')}`,
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    } : {
      'Content-Type': 'application/json',
      accept: 'application/json',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    },
  };
  if (body) {
    options.body = JSON.stringify(body);
  }
  return options;
};

export const requestPostFile = (f: File):RequestInit => {
  const formData = new FormData();
  formData.append('file', f);

  const options: RequestInit = {
    method: 'POST',
    cache: 'no-cache',
    headers: getCookie('auth') ? {
      Authorization: `Basic ${getCookie('auth')}`,
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    } : {
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    },
  };
  
  options.body = formData;
  return options;
};

export const requestDelete = (body: object | null = null):RequestInit => {
  const options: RequestInit = {
    method: 'DELETE',
    cache: 'no-cache',
    headers: getCookie('auth') ? {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Basic ${getCookie('auth')}`,
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    } : {
      'Content-Type': 'application/json',
      accept: 'application/json',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    },
  };
  if (body) {
    options.body = JSON.stringify(body);
  }
  return options;
};


export const fetchApi = async (url: string, options: RequestInit) => {

    const res = await fetch(API_URL + (url.indexOf('/') === 0 ? '' : '/') + url, options);
    
    return res;
  };

export const authUser = async (login: string, password: string) => {

    const auth = base64.encode(login + ':' + password);
    var r = requestGet();
    r.headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + auth
      };

    var res = await fetchApi('/v1/auth/login', r).then(resp => {
      if (resp.status == 400) {
        raiseNotice('error', 'Неверный логин или пароль');
        return false;
      }
      if (resp.status == 200) {
        return resp.json();
      }
    });
    if (res) {
        res.cookie_auth = auth;
    }

    return res;
}

export const getUserInfo = async() => {
    const auth = getCookie('auth');
    if (auth) {
        return await fetchApi('/v1/auth/login', requestGet()).then(handleHttpResponse).catch(handleHttpError);
    }
}