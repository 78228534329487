import React, { useEffect, useState } from 'react';
import styles from './Signup.module.scss';
import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/Button';
import { ReactComponent as ConfirmIcon } from '../../assets/img/signup-confirm.svg';
import { useSearchParams } from 'react-router-dom';
import { SelectInput } from '../../components/SelectInput';
import { getUserInfo, handleHttpError } from '../../services/api';
import { AccountSignupData } from '../../types/account';
import { confirmAccount, signupAccount } from '../../services/account';
import { passwordIsValid, validatePassword, validateRequired } from '../../services/utils';
import { PasswordInput } from '../../components/PasswordInput';

export function Signup() {
    require('../../assets/css/login.css');

    const [queryParams, setQueryParams] = useSearchParams();
    const [signupData, setSignupData] = useState<AccountSignupData>({ url: window.location.href });
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [activationMode, setActivationMode] = useState(false);
    const [activationResult, setActivationResult] = useState<boolean|undefined>(undefined);

    useEffect(() => {
        getUserInfo().then(user => {
            if (user)
                window.location.href = '/assessments/';
        });
    }, []);

    useEffect(() => {
        if (queryParams.get('confirm') && queryParams.get('em')) {
            setActivationMode(true);
            confirmAccount(queryParams.get('em') ?? '', queryParams.get('confirm') ?? '').then(res => {
                if (typeof res == 'boolean' && res) {
                    setActivationResult(true);
                } else
                    setActivationResult(false);
            }).catch(handleHttpError);
        }
    }, [ queryParams ])

    const validate = (data:AccountSignupData):boolean => {
        var r = true;

        r = r && (data.fio ?? '') != '';
        r = r && (data.email ?? '') != '';
        r = r && (data.inn ?? '') != '';
        r = r && (data.kpp ?? '') != '';
        r = r && (data.company ?? '') != '';
        r = r && (data.number_of_employees ?? '') != '';
        r = r && ((data.password ?? '') != '' && passwordIsValid(data.password ?? ''));

        return r;
    }

    const doSignup = () => {
        setShowValidationErrors(true);

        if (validate(signupData)) {

            signupAccount(signupData).then(res => {
                if (res.id)
                    setShowConfirmation(true);
            }).catch(handleHttpError);
        }
    }

    const validateConfirmPassword = (value: any):string|undefined => {
        if ((value ?? '') == '') {
            return 'Обязательное поле';
        } else if (signupData.password != confirmPassword)
            return '';
        return undefined;
    }

    return (
        <div className='loginForm signup'>
            {activationMode ? (
                <>
                    {typeof activationResult == 'boolean' && (
                        <>
                            <div className='maintitle'>БССГ</div>
                            <div className='message'>
                                <ConfirmIcon />
                                {activationResult ? (
                                    <>
                                        <div className='pri'>Аккаунт {queryParams.get('em')} подтвержден.</div>
                                        <Button label='Войти' onClick={() => { window.location.href = '/assessments'; }} />
                                    </>
                                ) : (
                                    <div className='pri'>Ошибка подтверждения аккаунта {queryParams.get('em')}</div>
                                )}
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                {!showConfirmation ? (
                    <>
                        <div className='left'>
                            <div className='inner'>
                                <div className='title'>БССГ</div>
                                <div className='subtitle top'>Для работы с опросами<br/>добавьте необходимую<br/>информацию</div>
                            </div>
                        </div>
                        <div className='right'>
                            <div className='inner'>
                                <div className='section-title'>Ваши данные</div>
                                <div className='data'>
                                    <TextInput label='ФИО' isRequired showValidationError={showValidationErrors} validate={validateRequired} value={signupData.fio} onChange={(e) => { setSignupData((prev:AccountSignupData) => ({...prev, fio: e.target.value })) }} />
                                    <TextInput label='Корпоративная электронная почта' isRequired showValidationError={showValidationErrors} validate={validateRequired} value={signupData.email} onChange={(e) => { setSignupData((prev:AccountSignupData) => ({...prev, email: e.target.value })) }} />
                                    <PasswordInput label='Введите пароль' isRequired showValidationError={showValidationErrors} validate={validatePassword} value={signupData.password} onChange={(e) => { setSignupData((prev:AccountSignupData) => ({...prev, password: e.target.value })) }} />
                                    {!passwordIsValid(signupData.password ?? '') && (<div className='psw_err'>Пароль должен состоять не менее, чем из восьми символов и включать заглавные и строчные латинские буквы, не менее одной цифры и специального символа</div>)}
                                    <PasswordInput label='Повторите пароль' isRequired showValidationError={showValidationErrors} validate={validateConfirmPassword} value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value ); }} />
                                    {signupData.password != confirmPassword && (<div className={'psw_err'}>Пароли не совпадают</div>)}
                                </div>
                                <div className='section-title'>Организация</div>
                                <div className='data'>
                                    <div className='cols-2'>
                                        <TextInput label='ИНН' isRequired showValidationError={showValidationErrors} validate={validateRequired} value={signupData.inn} onChange={(e) => { setSignupData((prev:AccountSignupData) => ({...prev, inn: e.target.value })) }} />
                                        <TextInput label='КПП' isRequired showValidationError={showValidationErrors} validate={validateRequired} value={signupData.kpp} onChange={(e) => { setSignupData((prev:AccountSignupData) => ({...prev, kpp: e.target.value })) }} />
                                    </div>
                                    <TextInput label='Название' isRequired showValidationError={showValidationErrors} validate={validateRequired} value={signupData.company} onChange={(e) => { setSignupData((prev:AccountSignupData) => ({...prev, company: e.target.value })) }} />
                                    <SelectInput label='Примерная численность сотрудников' isRequired showValidationError={showValidationErrors} validate={validateRequired} placeholder='Выберите из списка' 
                                        options={[ { value: 10, label: '10' }, { value: 100, label: '100' }, { value: 500, label: '500' }, { value: 1000, label: '1000' } ]} 
                                        value={{value: signupData.number_of_employees, label: signupData.number_of_employees }} onChange={(newValue, actionMeta) => { setSignupData((prev:AccountSignupData) => ({...prev, number_of_employees: newValue.value })) }}
                                    />
                                </div>
                                <div className='bottom'>
                                    <Button label='Продолжить' type='login' onClick={doSignup}></Button>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='maintitle'>БССГ</div>
                        <div className='message'>
                            
                            
                            <ConfirmIcon />
                            <div className='pri'>Мы отправили подтверждение<br/>на {signupData.email}</div>
                            <div className='sec'>Перейдите по ссылке, указанной в письме,<br/>чтобы начать работу</div>
                        </div>
                    </>
                )}
                </>
            )}
        </div>
    );
}